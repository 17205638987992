import React from "react";

const Javascript = () =>{
    return(
        <div>
            <h1>Javascript</h1>
        </div>
    )
}

export default Javascript;