import React from "react";

//CSS
import './Librarys.css';

const Librarys = () =>{
    return(
        <div>
            <h1>Librarys</h1>
            <div className="LB-body">
                <ul>
                    <li>React.js</li>
                    <li>Electron.js</li>
                </ul>
            </div>
        </div>
    );
}

export default Librarys;