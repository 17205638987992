function setTheme(themeName) {
  localStorage.setItem('theme', themeName);
  document.documentElement.className = themeName;
}

function keepTheme() {
  const theme = localStorage.getItem('theme');
  if (theme) {
      setTheme(theme);
  } else {
      setTheme('theme-dark'); // Default theme
  }
}

module.exports = {
  setTheme,
  keepTheme
}