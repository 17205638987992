import React, { useEffect } from "react";

//css
import './Home.css';

const Home = ({ height }) =>{
    useEffect(() => {
        //console.log("UETT Component Height:", height);
    }, [height]);
    
    return(
        <div className="Home-container" style={{ height: `${height - 50}px`, overflowY: "auto" }}>
            <h1>WELCOME to JES-PORTFOLIO</h1>
            <div>
                <h2>Overview</h2>
                <ul>
                    <li>What is this Page</li>
                    <li>How to use this Page</li>
                </ul>
            </div>
            <div className="Home-Body">
                <h2 id="what-is-this-page">What is this page</h2>
                <p>
                    This page is a Portfolio page for me to collect my Skills, my Projects 
                    and every information that might be interessting for some who wants
                    to work with me.
                </p>

                <h2 id="how-to-use-this-page">How to use this Page</h2>
                <p>
                    On the Left side you will see some buttons, by clicking those
                    you will be able to open some "Windows" with Additional infos
                </p>
                <img src={'/Images/desktop-buttons.png'} alt="Desktop Buttons" />
                <p className="home-devider-top">
                    If a Window moves to the back you can either click into the Window
                    to bring it back to the front or use the Taskbar to get the Window
                    back to the front by clicking the Window in their.
                </p>
                <img src={'/Images/navbar-tabs.png'} alt="Navbar Tabs"/>
                <p className="home-devider-top">
                    If you dont like the current Selected Theme you can Click the 
                    Button in the Top Left to Open the Themes Menu, inside their are 
                    a Selection of Themes that can be activated with on click.
                </p>
                <img src={'/Images/desktop-theme-options.png'} alt="Theming Options"/>
            </div>
        </div>
    );
}

export default Home;