import React from "react";

//CSS
import './IDEs.css';

const IDEs = () =>{
    return(
        <div>
            <h1>Itegrated Development Enviorments</h1>
            <div className="PL-body">
                <ul>
                    <li>Visual Studio</li>
                    <ul>
                        <li>Used for C# Development</li>
                    </ul>
                    <li>Visual Studio Code</li>
                    <ul>
                        <li>Used for... </li>
                        <ul>
                            <li>Python</li>
                            <li>JavaScript</li>
                            <li>HTML</li>
                            <li>CSS</li>
                            <li>Java</li>
                        </ul>
                        <li>Development</li>
                    </ul>
                </ul>
            </div>
        </div>
    );
}

export default IDEs;