import React, { useState, useEffect } from 'react';
import './../../App.css';
import './toggle.css';
import { setTheme } from './theme';

const Toggle = () => {
    const [currentTheme, setCurrentTheme] = useState(localStorage.getItem('theme') || 'theme-dark');
    const themes = ['theme-dark', 'theme-light', 'theme-blue', 'theme-dark-blue', 'theme-red', 'theme-dark-red', 'theme-green', 'theme-dark-green', 'theme-retro', 'theme-dontuse'];

    const handleThemeChange = (newTheme) => {
        setTheme(newTheme);
        setCurrentTheme(newTheme);
    }

    useEffect(() => {
        if (localStorage.getItem('theme')) {
            setCurrentTheme(localStorage.getItem('theme'));
        } else {
            setCurrentTheme('theme-dark');
        }
    }, []);

    return (
        <div className='theme-toggle'>
            <div className='theme-buttons'>
                {themes.map((themeName) => (
                    <button
                        key={themeName}
                        className={`theme-button ${themeName === currentTheme ? 'active' : ''}`}
                        onClick={() => handleThemeChange(themeName)}
                    >
                        {themeName.replace('theme-', '').toUpperCase()}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default Toggle;