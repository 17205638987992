import React, { useEffect } from "react";

//css
import './UnityEditorTimeTracker.css'

const UETT = ({ height }) => {
    useEffect(() => {
        //console.log("UETT Component Height:", height);
    }, [height]);

    return(
        <div className="Project-container" style={{ height: `${height - 50}px`, overflowY: "auto" }}>
            <h1>Unity Editor Time Tracker</h1>
            <div>
                <h2>Overview</h2>
                <ul>
                    <li>What is the Unity Editor Time Tracker</li>
                    <li>The Problem</li>
                    <li>The Solution</li>
                    <li>Future Updates</li>
                    <li>Links</li>
                </ul>
            </div>
            <div className="Project-Body">
                <h2 id="uett-what-is">What is the Unity Editor Time Tracker</h2>
                <p>
                    The Unity Editor Time Tracker is a Editor Plugin for Unity
                    that can Track the Time you Spent inside of Unity and on which
                    Day you spent how much time inside of Unity 
                </p>
                <h2 id="uett-problem">The Problem</h2>
                <p>
                    The problem I was facing when Using Unity was that I always like
                    to see my statistics if it is for a Game or a Game engine, I link
                    to now how much time I wasted on a bug or how much time I procrestinate
                    in Unity trying to make small changes instead documenting my Programm.
                </p>
                <p>
                    Unfortunaly Unity does not have such a Feature where i can Track 
                    my Times and Extrernal softwares can not directly track Unity for
                    me.
                </p>
                <h2 id="uett-solution">The Solution</h2>
                <p>
                    I created a Editor Plugin that that logs when you started the Launcher
                    adds it to the overall time as well as to the Dayli time so it can Track
                    how much time you spent.
                </p>
                <p>
                    The logged time is saved in a Json file in the Project itself, so it gets
                    saved by Version Controll programms.
                </p>
                <p>
                    When starting the Editor the Timer starts counting you can see ther Total
                    Session time as well as the current Session Time.
                </p>
                <p>
                    it also shows the Dayli hours inside the Editor View.
                </p>
                <h2 id="uett-updates">Future Updates</h2>
                <p>
                    the current Updates just aim to fix some bugs, that were found while using
                    the plugin.
                </p>
                <p>
                    The Idead for future updates are:
                </p>
                <ul>
                        <li>A Programm whith which you can Track multiple Projects</li>
                            <ul>
                                <li><p>
                                    this is Planed to be a piece of software where the User can look into
                                    and see every local project (later also the ones tracked online) and
                                    how many hours you spend on a dayli basis in Unity.
                                    </p></li>
                            </ul>
                        <li>Fix a lot of Bugs (their are some big bugs when using Fishnet, like resetting the current time)</li>
                        <li>A Online update with which you would be able to Log your time on a SQL or NoSQL Server</li>
                </ul>
                <h2 id="uett-links">Links</h2>
                <div className='Link-container' onClick={() => window.open("https://github.com/JostSchienke/UnityEditorTimeTracker")}>
                    <div className="Link-desktop-symbol">
                        <i className={`bx bxl-github Link-desktop-symbol-icon`}></i>
                    </div>
                    <div className='Link-Symbol-Text'>
                        <span>Unity Editor Time Tracker</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UETT;