import React from 'react';

//css
import './Projects.css';

//Projects
import UETT from './projects_pages/UnityEditorTimeTracker';

const Projects = ({ addWindow }) => {

  const OpenUETTProject = () => {
    const subfolderContent = (
      <UETT />
    );

    addWindow("Unity Editor Time Tracker", 300, 100, 800, 600, subfolderContent)
  }

  return (
    <div className="skills-container">
      <h1>Projects</h1>
      <div className="skills-grid">
        <div className="folder" onClick={() => OpenUETTProject()}>
          <i className="bx bxs-folder"></i>
          <span>Unity Editor Time Tracker</span>
        </div>
      </div>
    </div>
  );
};

export default Projects;