import React from "react";

const Python = () =>{
    return(
        <div>
            <h1>Python</h1>
        </div>
    )
}

export default Python;