import React from "react";

const Java = () =>{
    return(
        <div>
            <h1>Java</h1>
        </div>
    )
}

export default Java;