import React, {useState, useEffect} from 'react';
import { Rnd } from 'react-rnd';
import './Window.css';

const Window = ({ id, title, children, defaultPosition, width, height, onClose, bringToFront}) => {
  const [size, setSize] = useState({ width, height });

  useEffect(() => {
    //console.log(`Window size - Width: ${size.width}, Height: ${size.height}`);
  }, [size]);
  
  return (
    <Rnd
      default={{
        x: defaultPosition.x,
        y: defaultPosition.y,
        width: width,
        height: height,
      }}
      bounds="parent"
      className="window"
      dragHandleClassName="window-header"
      onMouseDown={() => bringToFront(id)} 
      onResizeStop={(e, direction, ref, delta, position) => {
        setSize({
          width: ref.style.width,
          height: ref.style.height,
        });
      }}
    >
      <div className="window-header">
        {title}
        <button className="close-button" onClick={() => onClose(id)}>X</button>
        
      </div>
      <div className="window-content" style={{ height: `calc(${size.height} - 50px)`, overflowY: "auto" }}>
        {React.cloneElement(children, { height: parseInt(size.height, 10) })}
      </div>
    </Rnd>
  );
};

export default Window;