import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <div className="about-text">
        <h1>About Me</h1>
        <p></p>
      </div>
    </div>
  );
};

export default About;