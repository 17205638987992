import React, {useState} from 'react';

//css
import './Navbar.css';

//utils
import Toggle from '../utils/toggle';

const Navbar = ({ windows, bringToFront, closeWindow }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSecondDropdownOpen, setIsSecondDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if (isDropdownOpen) {
      setIsSecondDropdownOpen(false);
    }
  };

  const toggleSecondDropdown = () => {
    setIsSecondDropdownOpen(!isSecondDropdownOpen);
  };

  return (
    <div className="navbar">
      <div className='NB-desktop-symbol' onClick={toggleDropdown}>
        <i className='bx bx-menu NB-desktop-symbol-icon'></i>
      </div>

      {isDropdownOpen && (
        <div className="dropdown-menu">
          <div className='NB-theme-symbol' onClick={toggleSecondDropdown}>
            Themes
          </div>

          {isSecondDropdownOpen && (
            <div className="dropdown-menu second-dropdown">
              <Toggle />
            </div>
          )}
        </div>
      )}

      {windows.map((win) => (
        <div key={win.id} className="navbar-item">
          <span onClick={() => bringToFront(win.id)}>{win.title}</span>
          <button onClick={() => closeWindow(win.id)}>X</button>
        </div>
      ))}
    </div>
  );
};

export default Navbar;