import React from "react";

//css
import './Enignes.css';

//Engines
import Unity from "./Unity/Unity";

const Engines = ({ addWindow }) =>{
    
    const OpenUnityPage = () =>{
        const subfolder = (
            <Unity />
        );

        addWindow("Unity Game Engine", 300, 300, 600, 400, subfolder)
    }   
    
    return(
        <div>
            <h1>Engines</h1>
            <ul>
                <li className="Engine-Clickable" onClick={OpenUnityPage}>Unity</li>
                    <ul>
                        <li>Expierience: 5 Years</li>
                    </ul>
            </ul>
        </div>
    );
}

export default Engines;