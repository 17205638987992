import React, { useState } from 'react';
import './DesktopSymbol.css';

function DesktopSymbol({ iconClass, onClick, tooltipText }) {
    const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0 });

    const handleMouseEnter = (e) => {
        setTooltip({ visible: true, x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (e) => {
        setTooltip((prev) => ({ ...prev, x: e.clientX, y: e.clientY }));
    };

    const handleMouseLeave = () => {
        setTooltip({ visible: false, x: 0, y: 0 });
    };
    
    return (
        <div className="desktop-symbol" 
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}>

            <i className={`bx ${iconClass} desktop-symbol-icon`}></i>
            {tooltip.visible && (
                <div
                    className="tooltip"
                    style={{ top: tooltip.y - 20, left: tooltip.x }}
                >
                    {tooltipText}
                </div>
            )}
        </div>
    );
}

export default DesktopSymbol;