import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

//Components
import Window from './components/window/Window';
import DesktopSymbol from './components/Desktop_Symbols/DesktopSymbol';
import Navbar from './components/Navbar/Navbar';

//Utils
import { keepTheme} from './components/utils/theme'

//pages
import Home from './components/Window_components/Home/Home';
import About from './components/Window_components/About/About_page'
import Skills from './components/Window_components/Skills/Skills';
import Projects from './components/Window_components/projects/Projects';
import Collections from './components/Window_components/collections/Collections';
import Links from './components/Window_components/Links/Links';

//CSS
import './App.css';

function App() {
  useEffect(() => {
    keepTheme();
  })

	const [windows, setWindows] = useState([
    { id: 1, title: 'Home Window', defaultPosition: { x: 300, y: 100 }, width: 1200, height: 800, content: <Home /> }
  ]);

  const addWindow = (title, x, y, width, height, content) => {
    if (!windows.find(win => win.title === title)) {
      setWindows(prevWindows => [
        ...prevWindows,
        {
          id: Date.now(),
          title,
          defaultPosition: { x, y },
          width,
          height,
          content
        }
      ]);
    }
  };

  const closeWindow = (id) => {
    setWindows(prevWindows => prevWindows.filter(win => win.id !== id));
  };

  const bringToFront = (id) => {
    setWindows(prevWindows => {
      const windowToFront = prevWindows.find(win => win.id === id);
      const otherWindows = prevWindows.filter(win => win.id !== id);
      return [...otherWindows, windowToFront];
    });
  };

  return (
    <div className="desktop">
      <Helmet>
        <title>JES-Portfolio</title>
      </Helmet>

      <Navbar windows={windows} bringToFront={bringToFront} closeWindow={closeWindow} />

      <div className="symbols-container">
      <DesktopSymbol 
          iconClass="bx-home" 
          onClick={() => addWindow('Home Window', 300, 100, 1200, 800, <Home />)}
          tooltipText="Home"
        />
        <DesktopSymbol 
          iconClass="bx-user" 
          onClick={() => addWindow('About Window', 300, 300, 600, 300, <About />)}
          tooltipText="About"
        />
        <DesktopSymbol 
          iconClass="bx-code-block"
          onClick={() => addWindow('Skills Window', 300, 300, 600, 450, <Skills addWindow={addWindow} />)}
          tooltipText="Skills"
        />
        <DesktopSymbol 
          iconClass="bx-memory-card"  
          onClick={() => addWindow('Projects Window', 300, 300, 300, 350, <Projects addWindow={addWindow} />)} 
          tooltipText="Projects"
        />
        <DesktopSymbol 
          iconClass="bx-collection"  
          onClick={() => addWindow('Collectables Window', 300, 300, 400, 300, <Collections addWindow={addWindow} />)} 
          tooltipText="Collectables"
        />
        <DesktopSymbol 
          iconClass="bx-link"  
          onClick={() => addWindow('Contact Window', 300, 300, 600, 400, <Links />)} 
          tooltipText="Conntact"
        />
      </div>

      {windows.map((win) => (
        <Window
          key={win.id}
          id={win.id}
          title={win.title}
          defaultPosition={win.defaultPosition}
          width={win.width}
          height={win.height}
          onClose={closeWindow}
          bringToFront={bringToFront}
        >
          {win.content}
        </Window>
      ))}
    </div>
  );
}

export default App;