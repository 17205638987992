import React from 'react';
import './Links.css';

const Links = () => {
    return (
    <div className="skills-container">
      <h1>Conntact and Links</h1>
      <div className='Link-container'onClick={() => window.open("https://www.linkedin.com/in/jost-schienke-3832b2303/")}>
        <div className="Link-desktop-symbol">
            <i className={`bx bxl-linkedin Link-desktop-symbol-icon`}></i>
        </div>
        <div className='Link-Symbol-Text'>
            <span>Jost Schienke (Linkedin)</span>
        </div>
      </div>
      <div className='Link-container' onClick={() => window.open("https://github.com/JostSchienke")}>
        <div className="Link-desktop-symbol">
            <i className={`bx bxl-github Link-desktop-symbol-icon`}></i>
        </div>
        <div className='Link-Symbol-Text'>
            <span>Jost Schienke (Github)</span>
        </div>
      </div>
    </div>
  );
};

export default Links;