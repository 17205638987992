import React from 'react';
import './Skills.css';

//skills
import ProgrammingLanguages from './skill_pages/Programming_Languages/Programming_Languages'; 
import Librarys from './skill_pages/Frameworks and Librarys/Librarys';
import IDEs from './skill_pages/IDEs/IDEs';
import Engines from './skill_pages/Engines/Engines';


const Skills = ({ addWindow }) => {
  const openLanguagesFolder = () =>{
    const subfolder = (
      <ProgrammingLanguages addWindow={addWindow}/>
    );

    addWindow("Programming Languages", 300, 300, 600, 400, subfolder);
  }

  const openLibrarysFolder = () =>{
    const subfolder = (
      <Librarys />
    );

    addWindow("Librarys", 300, 300, 600, 400, subfolder);
  }

  const openIDEsFolder = () =>{
    const subfolder = (
      <IDEs />
    );

    addWindow("Integrated Development Enviorments", 300, 300, 850, 400, subfolder);
  }

  const openEnginesFolder = () =>{
    const subfolder = (
      <Engines addWindow={addWindow}/>
    );

    addWindow("Engines", 300, 300, 600, 400, subfolder);
  }

  return (
    <div className="skills-container">
      <h1>Skills</h1>
      <div className="skills-grid">
        <div className="folder" onClick={() => openLanguagesFolder()}>
          <i className="bx bxs-folder"></i>
          <span>Programming Languages</span>
        </div>
        <div className="folder" onClick={() => openLibrarysFolder()}>
          <i className="bx bxs-folder"></i>
          <span>Librarys</span>
        </div>
        <div className="folder" onClick={() => openIDEsFolder()}>
          <i className="bx bxs-folder"></i>
          <span>Integrated Development Enviorments</span>
        </div>
        <div className="folder" onClick={() => openEnginesFolder()}>
          <i className="bx bxs-folder"></i>
          <span>Engines</span>
        </div>
      </div>
    </div>
  );
};

export default Skills;