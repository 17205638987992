import React from 'react';
import './Collections.css';

const Collections = ({ addWindow }) => {
  const openSaltCollection = () =>{
    const subfolderContent = (
      <div>
        <h2>Salt Collection</h2>
        <p>In this folder you will be able to look at my Salt collection</p>
      </div>
    );

    addWindow("Salt Collection", 300, 300, 400, 300, subfolderContent);
  }

  const openKeyboardCollection = () =>{
    const subfolderContent = (
      <div>
        <h2>Keyboard Collection</h2>
        <p>In this folder you will be able to look at my Keyboard Collection</p>
      </div>
    );

    addWindow("Keyboard Collection", 300, 300, 400, 300, subfolderContent);
  }

  return (
    <div className="skills-container">
      <h1>Collections</h1>
      <div className="skills-grid">
        <div className="folder" onClick={() => openSaltCollection()}>
          <i className="bx bx-pyramid"></i>
          <span>Salt</span>
        </div>
        <div className="folder" onClick={() => openKeyboardCollection()}>
          <i className="bx bxs-keyboard"></i>
          <span>Keyboards</span>
        </div>
      </div>
    </div>
  );
};

export default Collections;