import React from "react";

//CSS
import './Programming_Languages.css';

//pages
import Csharp from "./Csharp/Csharp";
import Javascript from "./Javascript/Javascript";
import Python from "./Python/Python";
import Java from "./Java/Java";


const ProgrammingLanguages = ({ addWindow }) =>{
    
    const OpenCsharp = () =>{
        const subfolder = (
            <Csharp />
        )

        addWindow("C#", 300, 300, 600, 400, subfolder)
    }

    const OpenJavascript = () =>{
        const subfolder = (
            <Javascript />
        )

        addWindow("Javascript", 300, 300, 600, 400, subfolder)
    }

    const OpenPython = () => {
        const subfolder = (
            <Python />
        )

        addWindow("Python", 300, 300, 600, 400, subfolder)
    }

    const OpenJava = () =>{
        const subfolder = (
            <Java />
        )

        addWindow("Java", 300, 300, 600, 400, subfolder)
    }
    
    return(
        <div>
            <h1>Programming Languages</h1>
            <div className="PL-body">
                <ul>
                    <li className="Languages-Clickable" onClick={OpenCsharp}>C#</li>
                        <ul>
                            <li>Expierience: 5 Years</li>
                        </ul>
                    <li className="Languages-Clickable" onClick={OpenJavascript}>JavaScript</li>
                        <ul>
                            <li>Expierience: 3 Years</li>
                        </ul>
                    <li className="Languages-Clickable" onClick={OpenPython}>Python</li>
                        <ul>
                            <li>Expierience: 3 Years</li>
                        </ul>
                    <li className="Languages-Clickable" onClick={OpenJava}>Java</li>
                        <ul>
                            <li>Expierience: 2 Years</li>
                        </ul>
                </ul>
            </div>
        </div>
    );
}

export default ProgrammingLanguages;